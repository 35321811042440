@import "../../variables.scss";

.mainHeader{
  position: relative;
  height: (60*$design-app-ratio)+rem;
  background: white;
  color: black;
}
.mainHeader-userInfo{
  position: absolute;
  left: (15*$design-app-ratio)+rem;
  top: (15*$design-app-ratio)+rem;
  height: (30*$design-app-ratio)+rem;
  display: flex;
  font-size: .8rem;
  a{
    img{
      height: 100%;
    }
  }
  >div{
    display: flex;
    flex-direction: column;
    margin-left: ($main-base-margin/2)+rem;
    button{
      background: transparent;
      border: 0;
      text-decoration: underline;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
    }
  }
}
.mainHeaderLogo{
  position: absolute;
  right: (15*$design-app-ratio)+rem;
  top: (15*$design-app-ratio)+rem;
  height: (30*$design-app-ratio)+rem;
}