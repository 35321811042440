@import "../../variables.scss";
@import "./Forms.scss";

.pageContent{
  form{
    p{
      margin: 0;
    }
    a{
      &.button{
        color: black;
        text-decoration: none;
      }
    }
  }
}
