@import "variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0 auto;
  max-width: 700px;
  background: black;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

h1{
  font-weight: normal;
  font-size: (28*$design-app-ratio)+rem;
  margin: ($main-base-margin)+rem ($main-base-margin*2)+rem ($main-base-margin*2)+rem;
}

.bold{
  font-weight: bold;
}

.button{
  display: inline-block;
  box-sizing: content-box;
  height: (32*$design-app-ratio)+rem;
  padding: 0 (15*$design-app-ratio)+rem;
  line-height: (30*$design-app-ratio)+rem;
  border: $color-highlight 2px solid;
  background: $color-highlight;
  border-radius: (20*$design-app-ratio)+rem;
  text-decoration: none;
  color: black;
  font-size: (16*$design-app-ratio)+rem;
  font-weight: bold;
  cursor: pointer;
  &:hover{
    transform: translate(2px);
  }
  &.buttonSecondary{
    background: transparent;
    border: $color-highlight 2px solid;
  }
}

.notificationsMessageWrp{
  background: #FDD;
  padding: 1rem;
  color: black;
  box-sizing: border-box;
  button{
    margin-top: ($main-base-margin/2)+rem;
  }
}

.notification-container{
  position: absolute;
  left: 0;
  top: 0;
  margin: 1rem;
  width: 100%;
  .notification{
    position: absolute;
    left: 50%;
    top: 0;
    background: $color-highlight-light;
    z-index: 1000;
    border-radius: 0.2rem;
    color: black;
    width: 20rem;
    margin-left: -10rem;
    padding: 1rem;
    box-sizing: border-box;
    font-size: 0.9rem;
    cursor:pointer;
    &:hover{
      transform: translate(-3px, -3px);
    }
  }
}
