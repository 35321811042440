@import "../../variables.scss";

.ticketItem{
  position: relative;
  margin: (10*$design-app-ratio)+rem $main-base-margin+rem;
  background: #EDEDED;
  border-radius: (10*$design-app-ratio)+rem;
  box-sizing: border-box;
  color: black;
  font-size: (14*$design-app-ratio)+rem;
  .ticketHeader{
    padding: (8*$design-app-ratio)+rem (14*$design-app-ratio)+rem (8*$design-app-ratio)+rem (10*$design-app-ratio)+rem;
    cursor: pointer;
    .ticketSupWrp{
      margin-bottom: (3*$design-app-ratio)+rem;
      display: flex;
      justify-content: space-between;
      .ticketFolio{
        border-radius: (5*$design-app-ratio)+rem;
        font-weight: bold;
      }
    }
    .ticketInfWrp{
      display: flex;
      justify-content: space-between;
      .ticketAmount{
        flex-shrink: 0;
      }
    }
  }
  .ticketExtraWrp{
    padding: (8*$design-app-ratio)+rem (14*$design-app-ratio)+rem (8*$design-app-ratio)+rem (10*$design-app-ratio)+rem;
    font-size: (12*$design-app-ratio)+rem;
    border-top: dashed 1px #ccc;
    ul{
      margin: (10*$design-app-ratio)+rem 0;
      padding: 0 (16*$design-app-ratio)+rem;
      li{
        margin: (3*$design-app-ratio)+rem 0;
      }
    }
    .ticketConcept{
      font-weight: bold;
    }
    .attachedFilesWrp{
      display: flex;
      list-style: none;
      border-top: dashed 1px #ccc;
      padding: ($main-base-margin/2)+rem 0;
      flex-wrap: wrap;
      li{
        display: flex;
        align-items: center;
        margin-right: (15*$design-app-ratio)+rem;
        cursor:pointer;
        border: 1px solid $color-grey-medium;
        padding: (5*$design-app-ratio)+rem;
        border-radius: (5*$design-app-ratio)+rem;
        max-width: 100%;
        box-sizing: border-box;
        &:hover{
          border-color: $color-grey-dark;
        }
        &:last-child{
          margin-right: 0;
        }
        &:before{
          content: "";
          width: (16.7*$design-app-ratio)+rem;
          height: (20*$design-app-ratio)+rem;
          background: url("../../assets/images/file_icon.png");
          background-repeat: no-repeat;
          display: inline-block;
          background-size: 100%;
          flex-shrink: 0;
        }
        > a{
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: (5*$design-app-ratio)+rem;
          text-decoration: none;
          color: inherit;
        }
      }
    }
    .ticketBtnsWrp{
      display: flex;
      justify-content: space-between;
      button{
        margin-left: (4*$design-app-ratio)+rem;
        font-size: (13*$design-app-ratio)+rem;
        padding: 0 (9*$design-app-ratio)+rem;
      }
      .buttonSecondary{
        border-color: $color-grey-medium-dark;
        color: $color-grey-medium-dark;
      }
    }
  }
  /* Status */
  &.ticketItem-approved,
  &.ticketItem-rejected{
    &:before{
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: (9*$design-app-ratio)+rem;
      height: 100%;
      border-radius: 0 (10*$design-app-ratio)+rem (10*$design-app-ratio)+rem 0;
    }
  }
  &.ticketItem-approved {
    &:before {
      background: $color-approved;
    }
  }
  &.ticketItem-rejected{
    &:before {
      background: $color-rejected;
    }
  }
  &.ticketItem-opened{
    .ticketInfWrp{
      font-weight: bold;
    }
    &.ticketItem-important{
      .ticketHeader{
        border-radius: (10*$design-app-ratio)+rem (10*$design-app-ratio)+rem 0 0;
        background: $color-important;
      }
    }
  }
  &.ticketItem-important{
    .ticketHeader{
      border-radius: (10*$design-app-ratio)+rem;
      background: $color-important;
    }
  }
  &.ticketItem-unread{
    background: white;
    .ticketInfWrp{
      font-weight: bold;
    }
  }
}