@import "../../variables";

.navigationMenu{
  >button{
    position: absolute;
    left: 0.5rem;
    top: 0.4rem;
    width: 3rem;
    height: 3rem;
    z-index: 110;
    border: 0;
    background-image: url("../../assets/images/burger.png");
    background-color:transparent;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
  ul{
    list-style: none;
    color: black;
    position: fixed;
    background: white;
    width: 0;
    top: $main-header-height+rem;
    left: 0;
    z-index: 100;
    overflow: hidden;
    transition: width 150ms ease-in-out;
    li{
      a{
        display: block;
        color: inherit;
        text-decoration: inherit;
        padding: 1rem;
        font-size: (18*$design-app-ratio)+rem;
        cursor: pointer;
        &.active{
          text-decoration: underline;
          font-weight: bold;
        }
        &:hover{
          color: inherit;
        }
      }
    }
  }
  &.opened{
    >button{
      background-image: url("../../assets/images/burger_close.png");
    }
    ul{
      width: 100%;
    }
  }
}

@media (min-width: 650px) {
  .navigationMenu {
    > button {
      display: none;
    }
    ul{
      position: static;
      width: 90%;
      display: flex;
      padding-top: 0.7rem;
      li{
        a{
          font-size: (14*$design-app-ratio)+rem;
        }
      }
    }
  }
}