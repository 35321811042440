@import "../../variables.scss";

.pageMainContainer{
  margin-bottom: 3rem;
}

h2{
  margin: (15*$design-app-ratio)+rem ($main-base-margin*2)+rem;
  font-weight: normal;
  font-size: (22*$design-app-ratio)+rem;
}
hr{
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-style: dashed;
  color: #666;
}
.searchBar{
  padding: 0 $main-base-margin+rem;
  input{
    width: 100%;
    height: (41*$design-app-ratio)+rem;
    padding: $main-base-margin+rem;
    background: $color-grey-dark;
    border: none;
    border-radius: (30*$design-app-ratio)+rem;
    box-sizing: border-box;
    color: $color-grey-medium;
    font-size: (16*$design-app-ratio)+rem;
    font-family: inherit;
  }
}
.titleCreateWrp{
  display: flex;
  margin: (15*$design-app-ratio)+rem $main-base-margin+rem (15*$design-app-ratio)+rem ($main-base-margin*2)+rem;
  justify-content: space-between;
  h2{
    margin: 0;
  }
}
.noTicketsMsg{
  margin: 0 ($main-base-margin*2)+rem;
  color: $color-grey-medium;
}