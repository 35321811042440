$color-highlight: #1EE7F7;
$color-highlight-light: #C5FDFF;
$color-important: #F7D705;
$color-approved: #78D705;
$color-rejected: #F75E36;
$color-grey-dark: #222;
$color-grey-medium-dark: #555;
$color-grey-medium: #CCC;
$color-grey-medium-light: #DDD;
$color-grey-light: #EEE;

$design-app-ratio: 1/16;

$main-base-margin: (18*$design-app-ratio);
$main-header-height: (60*$design-app-ratio);