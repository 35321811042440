@import "../../variables.scss";

.pageContent{
  margin: 0 $main-base-margin+rem $main-base-margin+rem;
  p{
    margin: $main-base-margin+rem;
  }
  a{
    text-decoration: underline;
    color: $color-highlight;
    &:hover{
      color: $color-highlight-light;
    }
  }
  form{
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    select{
      height: (41*$design-app-ratio)+rem;
      width: 100%;
      box-sizing: border-box;
      border: 1px #707070 solid;
      border-radius: (10*$design-app-ratio)+rem;
      margin-bottom: (10*$design-app-ratio)+rem;
      font-family: inherit;
      font-size: (18*$design-app-ratio)+rem;
      padding: 0 (10*$design-app-ratio)+rem;
    }
    .radioOptionsInput{
      height: (27*$design-app-ratio)+rem;
      margin: (13*$design-app-ratio)+rem 0;
      display: flex;
      justify-content: space-between;
      padding: 0 (13*$design-app-ratio)+rem;
      font-size: (18*$design-app-ratio)+rem;
      .radioBtns{
        input[type="radio"]{
          margin-left: (15*$design-app-ratio)+rem;
          margin-right: (5*$design-app-ratio)+rem;
        }
      }
    }
    .bankInfo{
      p{
        height: (27*$design-app-ratio)+rem;
        font-size: (18*$design-app-ratio)+rem;
        margin: (13*$design-app-ratio)+rem (13*$design-app-ratio)+rem;
      }
    }
    .fileAttachmentWrp{
      border-top: dashed 1px $color-grey-medium;
      padding: $main-base-margin+rem 0;
      p{
        padding: 0 $main-base-margin+rem;
        margin-bottom: (10*$design-app-ratio)+rem;
      }
      .attachFileBtn{
        &:before{
          content: "+";
          font-size: 1.3rem;
          display: inline-block;
          width: (24*$design-app-ratio)+rem;
          height: (24*$design-app-ratio)+rem;
          margin-right: (8*$design-app-ratio)+rem;
          border-radius: (5*$design-app-ratio)+rem;
          background: white;
          color: black;
          line-height: 1.5rem;
          text-align: center;
        }
        width: 100%;
        height: (41*$design-app-ratio)+rem;
        padding: 0 (10*$design-app-ratio)+rem;
        line-height: (41*$design-app-ratio)+rem;
        border: 1px #707070 solid;
        border-radius: 10px;
        color: white;
        background: black;
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .attachedFilesWrp{
        ul{
          li{
            height: (41*$design-app-ratio)+rem;
            padding: 0 (10*$design-app-ratio)+rem;
            line-height: (41*$design-app-ratio)+rem;
            border-radius: 10px;
            background: $color-highlight-light;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: black;
            margin-bottom: (10*$design-app-ratio)+rem;
            .fileName{
              flex-grow: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .detachFileBtn{
              font-size: 1rem;
              display: inline-block;
              width: (24*$design-app-ratio)+rem;
              height: (24*$design-app-ratio)+rem;
              margin-left: (8*$design-app-ratio)+rem;
              border: 1px solid $color-grey-medium-dark;
              border-radius: (5*$design-app-ratio)+rem;
              background: white;
              color: black;
              line-height: 1;
              text-align: center;
              flex-shrink: 0;
            }
          }
        }
      }
      #fileInput{
        width: 1px;
        height: 1px;
        position: absolute;
        bottom: -10000px;
        left: -10000px;
      }
    }
    .formBtnsWrp{
      text-align: right;
      .button{
        margin-left: (10*$design-app-ratio)+rem;;
      }
    }
  }
}