@import "../../variables";

.notificationsItem{
  position: relative;
  margin: (10*$design-app-ratio)+rem 0;
  background: #EDEDED;
  border-radius: (10*$design-app-ratio)+rem;
  box-sizing: border-box;
  color: black;
  font-size: (14*$design-app-ratio)+rem;
  padding: (10*$design-app-ratio)+rem;
  cursor: pointer;
  text-decoration: none;
  a{
    color: inherit;
    text-decoration: inherit;
    &:hover{
      color: inherit;
    }
  }
  h2{
    font-weight: bold;
  }
  h2, p{
    margin: 0;
    padding: 0;
    font-size: inherit;
  }
}